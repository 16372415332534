import React, { useEffect, useState } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'constants/routes';

import { useLazyUsersRetrieveQuery } from 'store/services/users';
import { selectUser, setIsAuthenticated, setUser } from 'store/slices/authSlice';

import MainPage from 'pages/MainPage';

import PrizesPage from 'pages/PrizesPage';
import { Box, SimpleGrid } from '@chakra-ui/react';
import Header from 'components/layout/Header';
import RulesPage from 'pages/RulesPage';
import TermsPage from './pages/TermsPage';
import GamePage from 'pages/GamePage';
import SignInPage from 'pages/SignInPage';
import SignUpPage from 'pages/SignUpPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import ResetPasswordConfirmPage from 'pages/ResetPasswordConfirmPage';
import ResultPage from 'pages/ResultPage';
import SettingsPage from 'pages/SettingsPage';
import ChangePasswordPage from 'pages/ChangePasswordPage';
import PolicyPage from 'pages/PolicyPage';
import ResponsibleGamingPolicy from 'pages/ResponsibleGamingPolicy';

function App() {
  const [getMe] = useLazyUsersRetrieveQuery();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const onLoad = async () => {
      try {
        const { data: user, error } = await getMe();
        if (error) throw new Error(error.data.detail);
        dispatch(setUser(user));
        dispatch(setIsAuthenticated());
      } catch (err) {
        console.error(err);
      }
      setChecked(true);
    };
    onLoad();
  }, []);

  useEffect(() => {
    window.OneSignalDeferred.push(async function (OneSignal) {
      try {
        if (user) {
          await OneSignal.login(user.id.toString());
        } else {
          await OneSignal.logout();
        }
      } catch (err) {
        console.error(err);
      }
    });
  }, [user]);

  const layout = searchParams.get('layout');
  const topOffset = searchParams.get('top_offset');
  const bottomOffset = searchParams.get('bottom_offset');

  if (!checked) return null;

  return (
    <SimpleGrid
      column="1"
      gridTemplateRows={layout ? 'min-content 1fr' : '48px 1fr'}
      pt={topOffset ? `${topOffset}px` : '0'}
      pb={bottomOffset ? `${bottomOffset}px` : '0'}
      minH="100vh"
    >
      <Header layout={layout} />
      <Box pb="40px" px="15px">
        <Routes>
          <Route path={routes.home} element={<MainPage />} />
          <Route path={routes.rules} element={<RulesPage />} />
          <Route path={routes.terms} element={<TermsPage />} />
          <Route path={routes.policy} element={<PolicyPage />} />
          <Route path={routes.gamingPolicy} element={<ResponsibleGamingPolicy />} />
          <Route path={routes.prizes} element={<PrizesPage />} />
          <Route path={routes.game} element={<GamePage />} />
          <Route path={routes.settings} element={<SettingsPage />} />
          <Route path={routes.signup} element={<SignUpPage />} />
          <Route path={routes.signin} element={<SignInPage />} />
          <Route path={routes.resetPassword} element={<ResetPasswordPage />} />
          <Route path={routes.resetPasswordConfirm} element={<ResetPasswordConfirmPage />} />
          {/* <Route path={routes.leaderboard} element={<LeaderboardPage />} /> */}
          <Route path={routes.result} element={<ResultPage />} />
          <Route path={routes.changePassword} element={<ChangePasswordPage />} />
        </Routes>
      </Box>
      {/* <Footer /> */}
    </SimpleGrid>
  );
}

export default App;
