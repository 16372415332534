import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const TermsPage = () => {

  return (
    <Box maxW="800px" mx="auto" p={4}>
      <Text size="title" textAlign="left">Terms & Conditions for Solo Survivor</Text>

      <Text mt="20px" mb="30px" fontSize="sm" opacity={0.5}>
        Last updated: 01/29/25
      </Text>

      <Text mb="30px" fontWeight="500">
        These Terms and Conditions ("Agreement") govern your participation in the game Solo Survivor,
        developed and operated by Rhino Studios Inc., a Fantasy Sports Company, located at 119
        Westchester Dr. Pittsburgh, PA 15215. By accessing or playing the Game, you agree to be
        bound by these Terms and Conditions. Please read this Agreement carefully before participating
        in the Game. If you do not agree to these Terms, you may not participate in the Game.
      </Text>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          1. Eligibility
        </Text>
        <Text mb="10px" fontWeight="500">To participate in Solo Survivor, you must meet the following requirements:</Text>
        <Text mb="10px" fontWeight="500"><Box as="span" fontWeight="800">Age Requirement:</Box> You must be at least 18 years of age at the time of registration.</Text>
        <Text mb="10px" fontWeight="500">
          <Box as="span" fontWeight="800">Geographic Restrictions:</Box> The Game is only available to residents of the following U.S. states and territories:
        </Text>
        <UnorderedList mb="20px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>Alaska</ListItem>
          <ListItem>California</ListItem>
          <ListItem>District of Columbia</ListItem>
          <ListItem>Florida</ListItem>
          <ListItem>Georgia</ListItem>
          <ListItem>Illinois</ListItem>
          <ListItem>Kansas</ListItem>
          <ListItem>Kentucky</ListItem>
          <ListItem>Minnesota</ListItem>
          <ListItem>Nebraska</ListItem>
          <ListItem>New Mexico</ListItem>
          <ListItem>North Carolina</ListItem>
          <ListItem>North Dakota</ListItem>
          <ListItem>Oklahoma</ListItem>
          <ListItem>Oregon</ListItem>
          <ListItem>Rhode Island</ListItem>
          <ListItem>South Carolina</ListItem>
          <ListItem>South Dakota</ListItem>
          <ListItem>Texas</ListItem>
          <ListItem>Utah</ListItem>
          <ListItem>West Virginia</ListItem>
          <ListItem>Wisconsin</ListItem>
          <ListItem>Wyoming</ListItem>
        </UnorderedList>
        <Text mb="10px" fontWeight="500">If you are not located in one of these states, you are not eligible to play.</Text>
        <Text mb="10px" fontWeight="500">
          By accessing the Game, you confirm that you meet these eligibility requirements. If you are found to be
          ineligible, your access to the Game may be revoked.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          2. Account Registration
        </Text>
        <Text mb="10px" fontWeight="500">
          To play "Solo Survivor," you will need to create an account. During the registration process, you
          will be required to provide certain personal information, including but not limited to your name,
          email address, date of birth, location and other information as may be requested.
        </Text>
        <Text mb="10px" fontWeight="500">
          You agree to provide accurate, current, and complete information, and to update such
          information promptly to keep it accurate and complete. You are solely responsible for
          maintaining the confidentiality of your account and for all activities that occur under your
          account.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          3. Game Rules and Conduct
        </Text>
        <Text mb="10px" fontWeight="500">
          By participating in "Solo Survivor," you agree to follow the rules set forth by the Game. These
          include, but are not limited to:
        </Text>
        <UnorderedList mb="20px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>Complying with all applicable laws and regulations.</ListItem>
          <ListItem>
            Not engaging in any behavior that is disruptive, offensive, abusive, or in violation of the rights of
            others.
          </ListItem>
          <ListItem>
            Not using cheats, hacks, bots, or other unauthorized methods to gain an advantage in the
            Game.
          </ListItem>
        </UnorderedList>
        <Text mb="10px" fontWeight="500">
          Failure to comply with these rules may result in the suspension or termination of your account,
          at the sole discretion of Rhino Studios Inc..
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          4. Intellectual Property
        </Text>
        <Text mb="10px" fontWeight="500">
          All content in the Game, including but not limited to graphics, text, logos, trademarks, and
          gameplay mechanics, is the property of Rhino Studios Inc. and is protected by intellectual
          property laws. You may not reproduce, distribute, or create derivative works of any part of the
          Game without the prior written consent of Rhino Studios Inc..
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          5. Privacy Policy
        </Text>
        <Text mb="10px" fontWeight="500">
          Your use of the Game is also governed by our Privacy Policy. By playing the Game, you consent
          to the collection and use of your personal data as outlined in the Privacy Policy.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          6. Limitation of Liability
        </Text>
        <Text mb="10px" fontWeight="500">
          To the fullest extent permitted by law, Rhino Studios Inc., its affiliates, employees, agents, and
          licensors shall not be liable for any direct, indirect, incidental, special, consequential, or punitive
          damages arising out of or relating to your participation in the Game. This includes, but is not
          limited to, damages for loss of profits, data, or other intangible losses, even if Rhino Studios Inc.
          has been advised of the possibility of such damages.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          7. Dispute Resolution and Governing Law
        </Text>
        <Text mb="10px" fontWeight="500">
          This Agreement shall be governed by and construed in accordance with the laws of the State of
          Pennsylvania, without regard to its conflict of law principles. Any dispute arising out of or relating
          to this Agreement shall be resolved through binding arbitration, to be conducted in Pittsburgh,
          PA.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          8. Changes to Terms and Conditions
        </Text>
        <Text mb="10px" fontWeight="500">
          Rhino Studios Inc. reserves the right to modify or amend these Terms and Conditions at any
          time, at its sole discretion. Any changes will be posted on this page, and the updated version
          will be effective immediately upon posting. Your continued participation in the Game after such
          changes constitutes your acceptance of the updated Terms and Conditions.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          9. Termination
        </Text>
        <Text mb="10px" fontWeight="500">
          Rhino Studios Inc. reserves the right to suspend or terminate your access to the Game at any
          time, without notice, if you violate these Terms and Conditions or engage in any conduct that we
          deem inappropriate or harmful to the Game or other players.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          10. Indemnification
        </Text>
        <Text mb="10px" fontWeight="500">
          You agree to indemnify and hold harmless Rhino Studios Inc., its affiliates, employees, agents,
          and licensors from any claims, losses, liabilities, damages, and expenses (including legal fees)
          arising out of your violation of these Terms and Conditions or your use of the Game.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" color="white" mb="10px">
          11. Miscellaneous
        </Text>
        <Text mb="10px" fontWeight="500">
          Severability: If any provision of this Agreement is found to be invalid or unenforceable, the
          remaining provisions shall remain in full force and effect.
        </Text>
        <Text mb="10px" fontWeight="500">
          Entire Agreement: This Agreement constitutes the entire agreement between you and Rhino
          Studios Inc. regarding your participation in the Game and supersedes all prior agreements or
          understandings.
        </Text>
        <Text mb="10px" fontWeight="500">
          If you have any questions or concerns regarding these Terms and Conditions, please contact us
          at <Link to="mailto:info@frankmichaelsmith.com">info@frankmichaelsmith.com</Link>.
        </Text>
        <Text mb="10px" fontWeight="500">
          By clicking "I Agree" or by accessing or using the Game, you acknowledge that you have read,
          understood, and agree to be bound by these Terms and Conditions.
        </Text>
      </Box>

      <Box textAlign="left" mt="40px" fontSize="sm" opacity={0.5}>
        <Text fontWeight="bold">Rhino Studios Inc.</Text>
        <Text>119 Westchester Dr. Pittsburgh, PA 15215</Text>
        <Text>solosurvivor.co</Text>
        <Text>info@frankmichaelsmith.com</Text>
        <Text>412-848-1937</Text>
      </Box>
    </Box>
  );
};

export default TermsPage;
