const routes = {
  home: '/',
  rules: '/rules',
  terms: '/terms_and_conditions',
  policy: '/privacy_policy',
  gamingPolicy: '/responsible_gaming_policy',
  leaderboard: '/leaderboard',
  signin: '/login',
  signup: '/signup',
  prizes: '/prizes',
  game: '/game',
  settings: '/settings',
  resetPassword: '/reset-password',
  resetPasswordConfirm: '/password/reset/confirm/:uid/:token',
  result: '/result',
  changePassword: '/change-password',
};

export default routes;
